import {
    createContext,
} from "react";

export interface UserInterface {
    id: string
}

let defaultValue:UserInterface = {
    id: ""
};

const UserContext = createContext(defaultValue);

export default UserContext;