import APIProvider from "@api/APIProvider";
import themes from "data/themes";

export async function createSite(userId, payload) {
  return await APIProvider.createSite(userId, payload);
}

export async function getSite(userId) {
  return await APIProvider.getSite(userId);
}

export async function getSiteByDomain(domain?:string) {
  return await APIProvider.getSiteByDomain(domain);
}

export async function checkFreeDomainExists(domain) {
  return await APIProvider.checkFreeDomainExists(domain);
}


export async function getTheme(theme) {
  let currentTheme = themes["generic"];

  if(theme) {
    if(themes[theme.id]) {
      currentTheme = themes[theme.id];
    }
  }

  return currentTheme;
}


