import APIProvider from "@api/APIProvider";

export * from "@api/services/UserService"
export * from "@api/services/SiteService"
export * from "@api/services/PageService"
export * from "@api/services/SectionService"
export * from "@api/services/ProductService"

export async function setAPIUser(user) {
}

export async function signUp(login) {
    let {
        email,
        password
    } = login;

    if(email && password) {
        return await APIProvider.signUp(login);
    }

    return false;
}

export async function signOut() {
    return await APIProvider.signOut();
}

export async function resetPassword(email) {
    return await APIProvider.resetPassword(email);
}

export async function signIn(login) {
    let {
        email,
        password
    } = login;

    if(email && password) {
        return await APIProvider.signIn(login);
    }

    return {
        user: false,
        session: false
    };
}

export async function getUserFromSession() {
    return await APIProvider.getUserFromSession()
}










export async function saveSite(payload) {
    return await APIProvider.saveSite(payload);
}

export async function getPage(userId, pageId) {
    return await APIProvider.getPage(userId, pageId);
}

export async function savePage(pageId, payload) {
    return await APIProvider.savePage(pageId, payload);
}

// Location Service


export async function getLocation(userId) {
    return await APIProvider.getLocation(userId);
}

export async function saveLocation(payload) {
    return await APIProvider.saveLocation(payload);
}

export async function getLocations(userId) {
    return APIProvider.getLocations(userId);
}



export async function createLocation(payload) {
    return await APIProvider.createLocation(payload);
}



export async function getRestaurantMenu(domain) {
    return await APIProvider.getRestaurantMenu(domain);
}

export async function saveRestaurantMenu(payload) {
    return await APIProvider.saveRestaurantMenu(payload);
}

export async function createNewSitePages(site) {

    let pages = [];

    if(site.type === "restaurant") {
        pages = [
            {
                "id": "home",
                "sections": [
                    {
                        "type": "restaurantHero"
                    },
                    {
                        "type": "banner",
                        "title": "About Us",
                        "desc": "<p>Welcome to our doner restaurant, located in the heart of Vancouver. We are a locally-owned and operated business, dedicated to serving up authentic, delicious doner kebabs made with marinated meats that are cooked on a rotisserie and then thinly sliced and served in a warm pita bread with fresh vegetables and sauces.</p><p><br></p><p>Our cozy and welcoming atmosphere is perfect for a quick lunch or a relaxing dinner, and our friendly staff are always happy to help you choose the perfect meal.</p><p><br></p><p>Thank you for choosing us as your go-to spot for delicious doner kebabs in Vancouver.</p>"
                    }
                ]
            }
        ]
    }

    return await APIProvider.createNewSitePages(site, pages);
}



export async function saveProduct(payload) {
    trimPayload(payload, ["name", "price", "desc", "options"])

    return await APIProvider.saveProduct(payload);
}

export async function getSiteTheme() {
    return {
        text: {},
        heading: {}
    };
}





/*
function buildSite(site) {
    site.url = process.env.HOST_URL + '/' + site.domain;

    if(site.type === "restaurant") {
        site.properties.hasOrdering = true // site.ordering && site.ordering.length;
        site.hasBooking = false;
    }

    if(!site.siteMenu) {
        if(site.type === "restaurant") {
            site.siteMenu = [
                {
                    label: "Menu",
                    linkTo: {
                        src: "menu"
                    },
                    hidden: !!((!site.properties.hasOrdering && !site.hasBooking) || !site.properties.hasOrdering)
                },
                {
                    label: "Locations",
                    linkTo: {
                        src: "locations"
                    }
                },
                {
                    label: "Book Table",
                    linkTo: {
                        src: "book"
                    },
                    btn: true,
                    hidden: !!(!site.hasBooking)
                },
                {
                    label: "Order Online",
                    linkTo: {
                        src: "order"
                    },
                    btn: true,
                    hidden: !!(!site.properties.hasOrdering)
                },
                {
                    label: "View Menu",
                    linkTo: {
                        src: "menu"
                    },
                    btn: true,
                    hidden: !!(site.properties.hasOrdering)
                }
            ];
        }
    }
}






export function getPage(domain, slug) {

    const getMasterPage = (slug) => {
        if(slug === "menu") {
            return {
                "title": "Menu",
                "sections": [
                  {
                    type: "menu",
                    menu: getRestaurantMenu(domain)
                  }
                ]
            }
        } else if(slug === "locations") {
            return {
                "title": "Locations",
                "sections": [
                  {
                    "type": "locations"
                  }
                ]
            }
        } else if(slug === "category") {
            return {
                "title": "CSS",
                "sections": [
                    {
                        "type": "blogArticles"
                    }
                ]
            }
        } else if(slug === "order") {
            return {
                "title": "Order Online",
                "sections": [
                  {
                    "type": "order",
                  }
                ]
            }
        }

        // check if it has ordering

        return false
    }

    let page = getMasterPage(slug);

    if(!page) {
        page = APIProvider.getPage(domain, slug);
    }

     



    

    return page;
}

export function getSitesStaticPaths() {
    const paths = [];
    for(let domain in sites) {
        const site = getSite(domain);
        let siteURL = `/${domain}`;
        paths.push(siteURL);

        if(site.type === "restaurant") {
            paths.push(`${siteURL}/menu`);
            paths.push(`${siteURL}/locations`);
        } else {
            if(sites[domain].pages) {
                for(let slug in sites[domain].pages) {
                    if(slug !== "home") {
                        paths.push(`${siteURL}/${slug}`)
                    }
                }
            }
        }
        
        
    }

    return paths;
}

export function getSiteTheme(domain) {
    return APIProvider.getSiteTheme(domain)
}
*/


const trimPayload = (payload, keys=[], settings={}) => {
    settings = {
        removeIfEmpty: false,
        ...settings
    }

    if(typeof payload === "object") {
        keys.forEach(key => {
            if(typeof payload[key] === "string") {
                payload[key] = payload[key].trim();

                if(settings.removeIfEmpty) {
                    if(!payload[key].length) {
                        delete payload[key];
                    }
                }
            } else if(typeof payload[key] === undefined) {
                delete payload[key]
            }
        })
    }
}

const cleanUrl = (payload: any, keys) => {
    if(Array.isArray(payload)) {
        payload.forEach(data => {
            keys.forEach(key => {
                if(typeof data[key] === "string") {
                    data[key] = data[key].split("?")[0].split("#")[0]
                }
            })
        })
    }
}