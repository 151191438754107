interface CardSubheader {
    className?:string,
    children?:any
}

export function CardSubheader({
    children,
    className,
    ...props
}: CardSubheader) {
    return (
        <div className={ `${ className } uppercase text-slate-500 text-xs font-bold` } { ...props }>
            { children }
        </div>
    )
}

interface Card {
    title?:string,
    subtitle?:string,
    noPadding?:string,
    className?:string,
    children?:any
}

export default function Card(props: Card) {
    let {
        title,
        subtitle,
        noPadding,
        actions,
        className="",
        anchor
    } = props;

    return (
        <div id={ anchor } className={ `bg-white mb-8 rounded ${ className }` }>
            {
                title &&
                <div className="px-8 py-5 border-b">
                    <div>
                        <div className="flex justify-between">
                            <div className="text-lg font-bold text-slate-500">
                                { title }
                            </div>
                            { actions }
                        </div>
                        {
                            subtitle &&
                            <div className="text-sm">
                                { subtitle }
                            </div>
                        }
                    </div>
                </div>
            }

            {
                props.children &&
                <div className={ noPadding ? "" : "px-8 py-6" }>
                    { props.children }
                </div>
            }
        </div>
    )
}