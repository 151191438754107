import themes from "data/themes";
import ITheme from "@api/types/ITheme";
import sites from "data/sites";
import generateDomain from "utils/generateDomain";

export default class APIProvider {
    getThemes(): ITheme[] {
        return themes;
    }

    getThemeData(themeId): ITheme {
        return this.getThemes().find(theme => theme.id === themeId) || this.getThemes().find(theme => theme.id === "generic")
    }

    getTheme(themeId: string = "generic"): ITheme | undefined {
        return this.getThemeData(themeId).design;
    }

    getTemplateSite(type) {
        return sites.find(siteData => siteData.site.free_domain === "phosaigon")
    }

    getAllSites() {}
    getCollections(userId, collectionIds) {}
    getProducts(userId, productIds) {}
    
    async checkFreeDomainExists(domain) { return false }

    async getFreeDomain(name) {
        let freeDomain = generateDomain(name);
        let foundDomain = false;

        let index = 1;

        while(!foundDomain) {
            let exists = await this.checkFreeDomainExists(freeDomain);

            if(exists) {
                freeDomain += index;
                index++;
            } else {
                foundDomain = true;
            }
        }

        return freeDomain;
    }

    async getCatalog(site) {
        const catalog = [];
        const collectionIds = site?.properties?.collection_ids;

        if(collectionIds?.length) {
            const collections = await this.getCollections(site.user_id, collectionIds);

            if(collections.length) {
                let productIds = [];
    
                collections?.forEach(collection => {
                    productIds = [
                        ...productIds,
                        ...collection.product_ids
                    ]
                })
    
                if(productIds.length) {
                    const products = await this.getProducts(site.user_id, productIds);

                    collectionIds.forEach(collectionId => {
                        let collection = collections.find(collection => collection.id === collectionId);
                        let catalogProducts = products.filter(product => collection.product_ids.includes(product.id));
            
                        catalog.push({
                            name: collection.name,
                            products: catalogProducts
                        })
                    })
                }
            }
        }

        return catalog;
    }

    catchError(error: { code?: string; message: string }): void {
        if (error) {
            if (process.env.NODE_ENV === "development") {
                console.log(error);
            }

            if (error?.code !== "PGRST116") {
                throw new Error(error.message);
            }
        }
    }
}
