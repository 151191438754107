import { useSite } from "effects/useSite";
import {
    createContext,
} from "react";

const SiteContext = createContext({});

export function SiteProvider({
    children
}) {
    const {
        site
    } = useSite();

    return (
        <SiteContext.Provider
            value={{
                site
            }}>
            {children}
        </SiteContext.Provider>
    )

    /*
    const {
        products,
        getProduct
    } = useProducts();
  
    
    */
}

export default SiteContext;