import {
    useState,
    useEffect,
    useCallback
} from "react";

import APIProvider from "@api/APIProvider";

import {
    getUserFromSession
} from "@api/APIService"

export default function() {
    const [ user, setUser ] = useState(null);
    const [ status, setStatus ] = useState("loading");

    const signOut = useCallback(() => {
        setUser(null);
        setStatus("signedOut")
    }, []);

    
    const signIn = useCallback((user) => {
        setUser(user);
        setStatus("signedIn");
    }, [])

    useEffect(() => {
        const fetchUser = async function() {
            let user;
            
            user = await APIProvider.getUserFromSession();

            if(user) {
                signIn(user);
            } else {
                signOut();
            }
        }

        fetchUser();
    }, [])

    return {
        user,
        status,
        signIn
    }
}