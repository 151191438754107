import generic from "data/themes/generic.json";
import opentummy from "data/themes/opentummytheme.json";
import sienna from "data/themes/sienna.json";

import phosaigon from "data/themes/phosaigon.json";
import brewbites from "data/themes/brewbites.json";
import tokyosushihouse from "data/themes/tokyosushihouse.json";
import jadepalace from "data/themes/jadepalace.json";
import pizzaparadiso from "data/themes/pizzaparadiso.json";
import mythosgreekkitchen from "data/themes/mythosgreekkitchen.json";
import flourcrumbbakery from "data/themes/flourcrumbbakery.json";
import bellaitalia from "data/themes/bellaitalia.json";
import seoulkitchen from "data/themes/seoulkitchen.json";
import saffronbistro from "data/themes/saffronbistro.json";
import losamigos from "data/themes/losamigos.json";
import burgerbliss from "data/themes/burgerbliss.json";



/*

import doner from "data/themes/doner.json";
import sushi2 from "data/themes/sushi2.json";
import singapore from "data/themes/singapore.json";
import taiwanese from "data/themes/taiwanese.json";
import sienna from "data/themes/sienna.json";
import bakery from "data/themes/bakery.json";
import ramen from "data/themes/ramen.json";
import hongkong from "data/themes/hongkong.json";
*/


export default [
    generic,
    opentummy,
    sienna,
    
    brewbites,
    flourcrumbbakery,
    burgerbliss,
    pizzaparadiso,
    jadepalace,
    tokyosushihouse,
    bellaitalia,
    mythosgreekkitchen,
    saffronbistro,
    losamigos,
    phosaigon,
    seoulkitchen

    /*
    pizza,
    doner,
    sushi2,
    singapore,
    taiwanese,
    
    chinese,
    bakery,
    ramen,
    sienna,
    hongkong
    */
]