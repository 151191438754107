// https://discord.com/api/webhooks/1132033915113177188/wyXu7fgILfNgNIzDgPFUj1xA9H8UF1CjSnrVr-3iZIRRKmfXkBHOt107kqTnAHVPfAUI

export async function sendToDiscord({
    fields,
    webhookId,
    webhookToken
}) {
    let payload = {};
    
    // let webhookId = "1073857942052405279";
    // let webhookToken = "OBHa5t_ukmqEfiTE15aw7RIZ5KfP-2AROLe2MfaFPY6O-FbaxppSVuYdXaGNXfFgqXys";

    let url = "https" + ":" + "//" + "discord" + "." + "com" + "/" + "api" + "/" + "webhooks" + "/" + webhookId + "/" +  webhookToken;

    payload.content = "------ NEW SUBMISSION ------";
    fields?.forEach(field => {
        payload.content += "\n" + field.label + ": " + field.value + " "
    })

    payload.content = payload.content + "\nDate: " + new Date().toString() + "\n";

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

    if(response?.ok) {
    } else {
    }
}