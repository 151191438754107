import {
  useEffect
} from "react";

import LoadingIcon from "icons/LoadingIcon.svg";

export default function AppLoading(props) {
  let {
    message
  } = props

  useEffect(() => {
    const block = (event) => {
      event.preventDefault(); 
      return false;
    }

    document.addEventListener("keydown", block);

    return () => {
      document.removeEventListener("keydown", block);
    }
  }, [])

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center flex-col bg-black/40 text-white">
      {
        message &&
        <div className="text-2xl font-bold mb-6">
          { message }
        </div>
      }
      <div>
        <LoadingIcon className="text-white" />
      </div>
    </div>
  )
}