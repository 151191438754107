import {
    useState,
    useEffect
} from "react";

const defaultClassName = "transition-colors py-2 px-6 bg-primary rounded-full text-white disabled:bg-slate-400 hover:bg-primary-light";

export default function Button({
    children,
    ...props
}) {
    const [ loaded, setLoaded ] = useState(false);
    const [ className, setClassName ] = useState(defaultClassName);

    useEffect(() => {
        let className = "transition-colors rounded-full disabled:cursor-not-allowed";

        if(props.size === "small") {
            className += " py-1 px-3 text-sm"
        } else if(props.size === "large") {
            className += " py-3 px-8";
        } else if(props.size === "none") {
        } else {
            className += " py-2 px-6"
        }
   
        if(props.variant === "outlined") {
            if(props.color === "danger") {
                className += " text-rose-700 border border-rose-700 enabled:hover:bg-rose-700 enabled:hover:text-white disabled:bg-slate-400"
            } else if(props.color === "black") {
                className += "  text-black border border-black disabled:opacity-40 enabled:hover:bg-black enabled:hover:text-white";
            } else {
                className += "  text-primary border border-primary disabled:opacity-40 enabled:hover:bg-primary enabled:hover:text-white";
            }
            
        } else if(props.variant === "link") {
            if(props.color === "danger") {
                className += "  text-rose-700 disabled:opacity-40 enabled:hover:bg-rose-700 enabled:hover:text-white";
            } else {
                className += "  text-primary disabled:opacity-40 enabled:hover:bg-primary enabled:hover:text-white";
            }
            
        } else if(props.variant === "text") {
            if(props.color === "danger") {
                className += "  text-rose-700 disabled:opacity-40 enabled:hover:text-rose-700 enabled:hover:opacity-90";
            } else {
                className += "  text-primary disabled:opacity-40 enabled:hover:text-primary enabled:hover:opacity-90";
            }
        } else {
            if(props.color === "danger") {
                className += " text-white bg-rose-700 enabled:hover:bg-rose-500 disabled:bg-slate-400"
            } else if(props.color === "black") {
                className += " text-white bg-black enabled:hover:bg-zinc-900 disabled:bg-slate-400"
            } else if(props.color === "green") {
                className += " text-white bg-green-600 enabled:hover:bg-green-700 disabled:bg-slate-400"
            } else {
                className += " text-white bg-primary enabled:hover:bg-primary-light disabled:bg-slate-400"
            }
        }

        if(props.className) {
            className += " " + props.className;
        }

        setClassName(className);
        setLoaded(true)
    }, [ 
        props.variant,
        props.className,
        props.size,
        props.color
    ]);

    if(!loaded) {
        return null;
    }



    return (
        <button
            type="button"
            { ...props } 
            className={ className }>
            { children }
        </button>
    )
}