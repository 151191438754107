import APIProvider from "@api/APIProvider";
import ISite from "@api/types/ISite";
import UserContext from "context/UserContext";
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

export function useSite() {
  const user = useContext(UserContext);
  const [ site, setSite ] = useState<ISite>({});
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if(user) {
        await APIProvider.getSite(user.id);
      }

      setLoading(false)
    })();
  }, [
    user
  ]);

  const saveSite = useCallback(async (payload) => {
    setSite({
      ...site,
      ...payload
    })

    await APIProvider.saveSite({
      id: site.id,
      ...payload
    })
  }, [
    site
  ]);

  const saveProperties = useCallback(async (payload) => {
    // to do finish this.
  }, [])

  return {
    site,
    loading,
    saveSite
  }
}