import sites from "data/sites";

/*
import {remark} from 'remark';
import remarkHTML from 'remark-html';
import matter from "gray-matter";

import fs from 'fs';
import path from 'path';
*/
import APIProvider from "@api/providers/APIProvider";


class FileStorage extends APIProvider {
    getAllSites() {
        return JSON.parse(JSON.stringify(sites));
    }

    async getSiteData(userId) {
        let site = this.getAllSites().find(siteData => siteData?.site?.user_id == userId) || {};

        return site;
    }

    async getSiteByDomain(domain, isCustomDomain) {
        const sites = this.getAllSites();

        if(isCustomDomain) {
            return sites.find(siteData => siteData?.site?.domain == domain)?.site;
        } else {
            return sites.find(siteData => siteData?.site?.free_domain == domain)?.site;
        }

        /*
        let siteData;

        if(isCustomDomain) {
            siteData = sites.find(siteData => siteData?.site?.domain == domain)?.site;
        } else {
            siteData = sites.find(siteData => siteData?.site?.free_domain == domain)?.site;
        }

        return siteData ? { ...siteData } : undefined;
        */
    }

    async getLocations(userId) {
        let siteData:any = await this.getSiteData(userId);

        return siteData?.locations;
    }

    async getPage(userId, slug) {
        let siteData:any = await this.getSiteData(userId);

        if(siteData?.pages) {
            for(var i = 0; i < siteData.pages.length; i++) {
                if(siteData.pages[i].slug === slug) {
                    return siteData.pages[i];
                }
            }
        }
    }

    async getPages(userId) {
        let siteData:any = await this.getSiteData(userId);

        return siteData?.pages;
    }

    async getCollections(userId) {
        let siteData:any = await this.getSiteData(userId);

        return siteData?.collections;
    }

    async getProducts(userId) {
        let siteData:any = await this.getSiteData(userId);

        return siteData?.products;
    }

    async getArticles(userId) {
        let siteData:any = await this.getSiteData(userId);

        const filePath = path.join(process.cwd(), 'data', siteData?.site?.free_domain, 'articles');

        try {
            const files = await fs.readdirSync(filePath);

            const articles = [];
            for(let i = 0; i < files.length; i++) {
                try {
                    let slug = files[i];

                    const fileContents = fs.readFileSync(`${filePath}/${ slug }`, 'utf8');

                    if(fileContents) {
                        const matterResult = matter(fileContents);
            
                        // Use remark to convert markdown into HTML string
                        const processedContent = await remark()
                            .use(remarkHTML)
                            .process(matterResult.content);
                        const contentHTML = processedContent.toString();
                
                        articles.push({
                            contentHTML,
                            slug: slug.replaceAll(".md", ""),
                            ...matterResult.data,
                        })
                    }
                } catch(e) {

                }
            }

            return articles;
        } catch(e) {
            console.log(e)
        }
    }

    async getArticleBySlug(userId, slug) {
        let siteData:any = await this.getSiteData(userId);

        const filePath = path.join(process.cwd(), 'data', siteData?.site?.free_domain, 'articles', `${slug}.md`);

        try {
            const fileContents = fs.readFileSync(filePath, 'utf8');

            if(fileContents) {
                const matterResult = matter(fileContents);
    
                // Use remark to convert markdown into HTML string
                const processedContent = await remark()
                    .use(remarkHTML)
                    .process(matterResult.content);
                const contentHTML = processedContent.toString();
        
                return {
                    contentHTML,
                    slug: slug.replaceAll(".md", ""),
                    ...matterResult.data,
                }
            }
        } catch(e) {

        }
    }
}

export default FileStorage;
