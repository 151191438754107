import APIProvider from "@api/providers/APIProvider";

import { createClient } from '@supabase/supabase-js'

const blacklist = new Set([
    "example",
    "stage",
    "staging",
    "cdn",
    "blog",
    "support",
    "local",
    "localhost",
    "test",
    "dev",
    "http",
    "https",
    "www",
    "sitemaps",
    "sitemap",
    "undefined"
]);

function isDomainBlacklisted(domain) {
    return blacklist.has(domain.toLowerCase());
}

class Supabase extends APIProvider {
    supabase: any;
    
    constructor() {
        super();

        this.supabase = createClient('https://mdhynbkejqeoheejyjcg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1kaHluYmtlanFlb2hlZWp5amNnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA0NTUyMTEsImV4cCI6MTk4NjAzMTIxMX0.XH9upSvoPB7qph5c81BhfStTQk726rLAXuqrO6zHhOY')
    }

    // --- Helpers ----
    async insert(table, payload) {
        if(table && payload) {
            const { data, error } = await this.supabase
                .from(table)
                .insert(payload)
                .select("id")
                .limit(1)
                .single()

            this.catchError(error);

            payload.id = data.id;
        
            return payload.id;
        } else {
            throw new Error();
        }
    }

    async update(table, payload) {
        const { error } = await this.supabase
            .from(table)
            .update(payload)
            .eq("id", parseInt(payload.id))

        this.catchError(error);
    }

    
    async delete(table, payload) {
        const { error } = await this.supabase
            .from(table)
            .delete()
            .in('id', payload)

        this.catchError(error);
    }


    // ---  User  ---
    async signIn({ email, password }) {
        const { data, error } = await this.supabase.auth.signInWithPassword({
            email,
            password,
        });

        return {
            user: data?.user,
            error
        }
    }

    async signUp({ email, password }) {
        const { user, session, error } = await this.supabase.auth.signUp({
            email,
            password
        });

        return {
            user,
            error
        }
    }

    async resetPassword({email}) {
        const { data, error } = await this.supabase.auth.resetPasswordForEmail(email);
    }
    
    async signOut() {
        const { error } = await this.supabase.auth.signOut()
        this.catchError(error);
    }

    async getUserFromSession() {
        const { data } = await this.supabase.auth.getUser();

        return data?.user;
    }

    async updateUser(payload) {
        const { data, error } = await this.supabase.auth.updateUser(payload)

        this.catchError(error);

        return data;
    }

    // ---- Site --- 
    async createSite(payload) {
        return this.insert("sites", payload);
    }

    async getSite(userId) {
        const { data, error } = await this.supabase
            .from('sites')
            .select()
            .eq("user_id", userId)
            .limit(1)
            .single()

        this.catchError(error);

        return data;
    }

    async getSiteByDomain(domain="", isCustomDomain=false) {
        if(isDomainBlacklisted(domain)) {
            return;
        }

        if(domain) {
            const { data, error } = await this.supabase
                .from('sites')
                .select()
                .eq(isCustomDomain ? "domain" : "free_domain", domain)
                .limit(1)
                .single()

            this.catchError(error);
                
            return data;
        }

        
    }

    async saveSite(payload) {
        await this.update("sites", payload);
    }

    async checkFreeDomainExists(domain) {
        const { data, error } = await this.supabase
            .from('sites')
            .select()
            .eq("free_domain", domain)
            .limit(1)
            .single();

        if(data) {
            return true;
        } else {
            return false;
        }
    }

    // ---- Locations ----
    async createLocation(payload) {
        return await this.insert("locations", payload);
    }

    async getLocation(userId) {
        const { data, error } = await this.supabase
            .from('locations')
            .select()
            .eq("user_id", userId)
            .limit(1)
            .single()

        this.catchError(error);

        return data;
    }

    async saveLocation(payload) {
        const { error } = await this.supabase
            .from('locations')
            .update(payload)
            .eq('user_id', payload.user_id)

        this.catchError(error);

        return this;
    }

    // ----------- CATALOG -----------
    async removeCatalog(userId) {
        var { error } = await this.supabase
            .from("products")
            .delete()
            .eq('user_id', userId)

        this.catchError(error);

        var { error } = await this.supabase
            .from("collections")
            .delete()
            .eq('user_id', userId)

        this.catchError(error);
    }

    // ----------- PRODUCTS -----------
    async createProducts(products) {
        const { data, error } = await this.supabase
            .from('products')
            .insert(products)
            .select("id")

        let productIds = data.map(d => d.id);

        return productIds;
    }

    async deleteProducts(productIds) {
        const { error } = await this.supabase
            .from('countries')
            .delete()
            .in('id', productIds)

        return error;
    }

    async createProduct(payload) {
        const { data, error } = await this.supabase
            .from('products')
            .insert({
                ...payload
            })
            .select("id")
            .limit(1)
            .single()

        this.catchError(error);
        
        return data.id;
    }


    // ----------- COLLECTIONS -----------
    async createCollection(payload) {
        return this.insert("collections", payload);
    }

    async saveCollection(payload) {
        this.update("collections", payload);
    }

    async deleteCollection(payload) {
        return this.delete("collections", payload);
    }

    // Page
    async createPage(payload) {
        return this.insert("pages", payload)
    }

    async createSections(sections) {
        const { data, error } = await this.supabase
            .from('sections')
            .insert(sections)
            .select("id")

        let ids = data.map(data => data.id);

        return ids;
    }

    async saveSections(sections=[]) {
        for(let i = 0; i < sections.length; i++) {
            let section = sections[i];

            const { error } = await this.supabase
                .from('sections')
                .update(section)
                .eq("id", parseInt(section.id))

            this.catchError(error);
        }
        
        return this;
    }

    // Blog
    async getArticleBySlug(userId, articleSlug) {
        // todo
        return false;
    }

    async getArticles(userId) {
        // todo
        return false;
    }

    // Orders

    async getOrders({
        userId,
        locationId,
        due_at
    }: {
        userId: string,
        locationId?: string,
        due_at?: string
    }) {
        const query = this.supabase
            .from('orders')
            .select("*")
            .eq("user_id", userId)
            .not("status", "in", "(cancelled)")

        if(due_at) { // date
            const startDate = new Date(due_at);
            startDate.setHours(0, 0, 0, 0); // Set the time to the start of the day

            const endDate = new Date(due_at);
            endDate.setHours(23, 59, 59, 999); // Set the time to the end of the day

            const startTimestamp = startDate.toISOString();
            const endTimestamp = endDate.toISOString();

            query.gte('due_at', startTimestamp) 
            query.lte('due_at', endTimestamp)
        }

        const { data, error } = await query;

        this.catchError(error);

        return data;
    }
    
    
    async getOrder({
        siteId,
        orderId,
        locationId=""
    }) {
        const { data, error } = await this.supabase
            .from('orders')
            .select()
            .eq("id", orderId)
            .eq("site_id", siteId)
            .limit(1)
            .single()

        if(error) {
            this.catchError(error);
        } else {
            data.lines = await this.getOrderLines(orderId) || [];
        }
        
        return data;


            /*
        if(locationId) {
            // query.eq("location_id", locationId)
        }

        const { order, error } = await query.single();

        console.log("getOrder", order, error, orderId, siteId)

        this.catchError(error);

        if(order) {
            order.lines = await this.getOrderLines(order.id) || [];
        }

        return order;
        */
    }

    async getOrderLines(orderId) {
        const { data, error } = await this.supabase
            .from('lines')
            .select("*")
            .eq("order_id", orderId)

        this.catchError(error);

        return data;
    }

    async subscribeToOrders({
        locationId,
        callback
    }) {
        await this.supabase
            .channel('any')
            .on('postgres_changes', { 
                event: '*', 
                schema: 'public', 
                table: 'orders',
                // filter: `location_id=eq.${ locationId }`
            }, payload => {
                console.log(payload);
                callback(payload.new)
            })
            .subscribe()
    }

    async saveOrder(order) {
        const { error } = await this.supabase
            .from('orders')
            .update(order)
            .eq("id", parseInt(order.id))

        this.catchError(error);
        return this;
    }

    
    async subscribeToOrder(orderId, callback) {
        await this.supabase
            .channel('any')
            .on('postgres_changes', { 
                event: '*', 
                schema: 'public', 
                table: 'orders',
                filter: `id=eq.${ orderId }`
            }, payload => {
                callback(payload.new)
            })
            .subscribe()
    }

    async retrieve(table, id, column="id") {
        const { data, error } = await this.supabase
            .from(table)
            .select()
            .eq(column, parseInt(id))
            .limit(1)
            .single()

        this.catchError(error);

        return data;
    }

    async createOrder(order) {
        if(order?.lines?.length) {
            let payload = JSON.parse(JSON.stringify(order));
            delete payload.lines;
    
            let order_id = await this.insert("orders", payload);
            
            let newLines = order.lines.map(line => {
                return {
                    added_at: line.added_at,
                    product: line?.product,
                    order_id,
                    product_id: line?.product?.id,
                    total: line.total,
                    options: line.options
                }
            })
    
            const { data, error } = await this.supabase
                .from('lines')
                .insert(newLines)
                .select("id")
    
            this.catchError(error);
    
            let ids = data.map(data => data.id);
            for(let i = 0; i < ids.length; i++) {
                newLines[i].id = ids[i];
            }
            // insert the lines
    
            return {
                id: order_id,
                lines: newLines
            }
        }
    }













    

    // gotta review the stuff below
    





















































    


    

    




    

    

    

    

    

    

    

    


    async getLocations(userId) {
        const { data, error } = await this.supabase
            .from('locations')
            .select()
            .eq("user_id", userId)

        this.catchError(error);

        return data;
    }

    

    

    async getProducts(userId, productIds=[]) {
        const query = await this.supabase
            .from('products')
            .select("id,name,medias,price,desc")
            .eq("user_id", userId)

        if(productIds.length) {
            query.in("id", productIds);
        }

        const { data, error } = await query;

        this.catchError(error);

        return data;
    }

    async getCollections(userId) {
        const { data, error } = await this.supabase
            .from('collections')
            .select("id,name,product_ids")
            .eq("user_id", userId)

        this.catchError(error);

        return data;
    }

    async getProduct(productId) {
        const { data, error } = await this.supabase
            .from('products')
            .select()
            .eq("id", parseInt(productId))
            .limit(1)
            .single()

        this.catchError(error);

        return data;
    }

    async saveProduct(payload) {
        if(payload.id) {
            const { error } = await this.supabase
                .from('products')
                .update(payload)
                .eq("id", parseInt(payload.id))

            this.catchError(error);
        }

        return this;
    }

    
    

    

    

    async get(table, payload) {
        const { data, error } = await this.supabase
            .from(table)
            .select()
            .eq("id", parseInt(payload.id))
            .limit(1)
            .single()

        this.catchError(error);

        return data;
    }

    async addProductToCollection(collectionId, productIds) {
        const collection = await this.get("collections", { id: collectionId });

        if(collection?.id) {
            let product_ids = [
                ...(collection.product_ids || []),
                ...productIds
            ]

            const { error } = await this.supabase
                .from('collections')
                .update({
                    product_ids
                })
                .eq("id", collection.id);

            this.catchError(error);
        }
        
        return this;
    }

   

   

    async getPage(userId, slug) {
        if(slug) {
            const { data, error } = await this.supabase
                .from('pages')
                .select()
                .eq("slug", slug)
                .eq("user_id", userId)
                .limit(1)
                .single()

            this.catchError(error);

            return data;
        }
    }

    async getPageBySlug(userId, slug) {
        if(slug) {
            const { data, error } = await this.supabase
                .from('pages')
                .select()
                .eq("slug", slug)
                .eq("user_id", userId)
                .limit(1)
                .single()

            this.catchError(error);

            return data;
        }
    }

    async getSections(sectionIds) {
        const { data, error } = await this.supabase
            .from('sections')
            .select()
            .in('id', sectionIds)

        this.catchError(error);

        return data;
    }

    
}

export default Supabase;