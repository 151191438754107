import {
    useState,
    useCallback,
    useEffect
} from "react";

import Link from "next/link";
import Card from "honeyjar/Card";

import Head from 'next/head'

import {
    signUp,
    signIn,
    resetPassword
} from "@api/APIService";

import isEmptyStruct from "utils/isEmptyStruct";
import TextInput from "honeyjar/TextInput";
import Button from 'honeyjar/Button';
import isValidEmail from "utils/isValidEmail";
import AppLoading from "components/core/AppLoading";
import APIProvider from "@api/APIProvider";
import AppLogo from "components/core/AppLogo";
import { useRouter } from "next/router";
import { sendToDiscord } from "utils/discord";

export default function LoginPage(props) {
    let {
        onSignIn
    } = props;

    const { query } = useRouter();

    const [ showPage, setShowPage ] = useState(query.register ? "signUp" : "signIn");
    const [ loading, setLoading ] = useState(false);
    
    const [ errors, setErrors ] = useState([]);
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        let payload = {
            email: String(email || "").trim(),
            password: String(password || "").trim()
        }

        let errors = [];

        if(!isValidEmail(payload.email)) {
            errors.push({
                name: "email",
                message: "Please enter a valid email"
            })
        }

        if(showPage === "signIn" || showPage === "signUp") {
            if(!payload.password) {
                errors.push({
                    name: "password",
                    message: "Please enter a password"
                })
            }
        }

        setErrors(errors);

        if(!errors.length) {
            setLoading(true);

            let response;

            if(showPage === "forget") {
                response = await APIProvider.resetPassword(payload);

                setShowPage("forgetSent");
            } else {
                if(showPage === "signIn") {
                    response = await APIProvider.signIn(payload);

                    if(response.error) {
                        setErrors([
                            {
                                "name": "email",
                                "message": "Incorrect email or password."
                            },
                            {
                                "name": "password",
                                "message": "Incorrect email or password."
                            }
                        ]);
                    }
                } else if(showPage === "signUp") {
                    response = await APIProvider.signUp(payload);
                    await sendToDiscord({
                        fields: [
                            {
                                label: "Type",
                                value: "sign up"
                            },
                            {
                                label: "Email",
                                value: payload.email
                            }
                        ],
                        webhookId: '1132033915113177188',
                        webhookToken: 'wyXu7fgILfNgNIzDgPFUj1xA9H8UF1CjSnrVr-3iZIRRKmfXkBHOt107kqTnAHVPfAUI'
                    })
                }
                
                // check if sign up shold be here
                if(response?.user) {
                    onSignIn(response?.user);
                }
            }

            setLoading(false);
        }
    }, [
        email,
        password,
        showPage
    ])

    
    const [ title, setTitle ] = useState("Login");

    useEffect(() => {
        setPassword("");
        setErrors([]);

        let newTitle = "Sign In";
        if(showPage === "signUp") {
            newTitle = "Sign Up";
        } else if(showPage === "forget") {
            newTitle = "Reset your password";
        }

        setTitle(newTitle);
    }, [ showPage ])


    return (
        <div>
            <Head>
                <title>{ title } — { process.env.APP_NAME }</title>
            </Head>
            <header className="px-6 pt-6">
                <Link href={ process.env.APP_HOST } target="_blank">
                    <div className="flex items-center text-lg font-bold">
                        <AppLogo className="mr-2 w-8" />
                        { process.env.APP_NAME }
                    </div>
                </Link>
            </header>
            <main className="max-w-[600px] mx-auto mt-16">
                <form onSubmit={ onSubmit }>
                    {
                        showPage === "signIn" &&
                        <Card title="Sign In">
                            <div className="mb-4">
                                New to { process.env.APP_NAME }? 
                                <Link 
                                    href="#" 
                                    className="text-blue-700 hover:text-blue-500 font-bold ml-1"
                                    onClick={ event => {
                                        event.preventDefault();
                                        setShowPage("signUp");
                                    } }>Sign up</Link>
                            </div>

                            <TextInput
                                label="Email"
                                value={ email }
                                placeholder="name@example.com"
                                maxLength="200"
                                required
                                autoFocus
                                size="lg"
                                errors={ errors }
                                name="email"
                                type="email"
                                onChange={ event => setEmail(event.target.value) } />

                            <TextInput
                                label="Password"
                                placeholder="Enter password"
                                value={ password }
                                size="lg"
                                errors={ errors }
                                name="password"
                                maxLength="200"
                                required
                                type="password"
                                onChange={ event => setPassword(event.target.value) } />

                            <div className="mb-4">
                                <Link 
                                    href="#" 
                                    className="text-blue-700 hover:text-blue-500 font-bold ml-1 text-sm"
                                    onClick={ event => {
                                        event.preventDefault();
                                        setShowPage("forget");
                                    } }>Forgot password?</Link>
                            </div>

                            <div className="text-right">
                                <Button type="submit">
                                    Sign In
                                </Button>
                            </div>
                        </Card>
                    }

                    {
                        showPage === "signUp" &&
                        <Card title="Let's create your account.">
                            <TextInput
                                label="Email"
                                value={ email }
                                placeholder="name@example.com"
                                maxLength="200"
                                autoFocus
                                size="lg"
                                required
                                errors={ errors }
                                name="email"
                                type="email"
                                onChange={ event => setEmail(event.target.value) } />

                            <TextInput
                                label="Password"
                                placeholder="Enter password"
                                value={ password }
                                errors={ errors }
                                name="password"
                                size="lg"
                                maxLength="200"
                                required
                                type="password"
                                onChange={ event => setPassword(event.target.value) } />

                            <div className="mb-4 text-slate-500 text-sm">
                                By continuing, I agree to { process.env.APP_NAME }'s' <a href={ `${process.env.APP_HOST}/terms-of-use` } target="_blank" className="underline">terms of use</a>.
                            </div>

                            <div className="flex justify-between items-center mt-10">
                                <Button 
                                    onClick={ event => {
                                        event.preventDefault();
                                        setShowPage("signIn");
                                    } }
                                    variant="outlined">
                                    Back to login
                                </Button>
                                <Button 
                                    type="submit">
                                    Sign Up
                                </Button>
                            </div>
                        </Card>
                    }

                    {
                        showPage === "forget" &&
                        <Card title="Reset your password">
                            <div className="mb-4">
                                Enter the email address you used to register with.
                            </div>

                            <TextInput
                                label="Email"
                                value={ email }
                                placeholder="name@example.com"
                                maxLength="200"
                                required
                                size="lg"
                                autoFocus
                                errors={ errors }
                                name="email"
                                type="email"
                                onChange={ event => setEmail(event.target.value) } />

                            <div className="flex justify-between items-center mt-10">
                                <Button 
                                    onClick={ event => {
                                        event.preventDefault();
                                        setShowPage("signIn");
                                    } }
                                    variant="outlined">
                                    Back to login
                                </Button>
                                <Button type="submit">
                                    Send instructions
                                </Button>
                            </div>
                        </Card>
                    }

                    {
                        showPage === "forgetSent" &&
                        <Card title="Reset Password Email Sent">
                            <div className="mb-4">
                                Check your inbox
                            </div>

                            <div>
                                A link was sent to <b>{email}</b> to reset your password. 
                                If you don't see it in your inbox, remember to check your spam folder.
                            </div>
                        </Card>
                    }

                    
                </form>
                
            </main>

            {
                loading &&
                <AppLoading />
            }
        </div>
    )
}

/*

    const [ showPage, setShowPage ] = useState("login");
    const [ labels, setLabels ] = useState({});
    const [ submitDisabled, setSubmitDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    const [ showRegister, setShowRegister ] = useState(false);
    const [ signingIn, setSigningIn ] = useState(false);


    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ showPassword, setShowPassword ] = useState(false);

    const [ errors, setErrors ] = useState({});


    useEffect(() => {
        if(showPage === "login") {
            setLabels({
                title: "Sign in",
                submit: "Sign In"
            })
        } else if(showPage === "forgot") {
            setLabels({
                title: "Password reset",
                desc: "We'll send a password reset link to this email.",
                submit: "Reset password"
            })
        } else if(showPage === "register") {
            setLabels({
                title: "Register"
            })
        }

    }, [ showPage ]);

    useEffect(() => {
        let status = false;

        if(email.length !== 0) {
            if(showPage === "register" || showPage === "login") {
                if(password.length === 0) {
                    status = true;
                }
            }
        } else {
            status = true;
        }

        setSubmitDisabled(status)
    }, [
        showPage,
        email,
        password
    ])
    
    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        let payload = {
            email: String(email || "").trim(),
            password: String(password || "").trim()
        }

        let response;

        setLoading(true);
        try {
            if(showPage === "login") {
                response = await signIn(payload);
            } else if(showPage === "register") {
                response = await signUp(payload);
            } else if(showPage === "forgot") {
                alert("forgot please")
            }

            setLoading(false);
        } catch(e) {
                
            setLoading(false);
        }



        return;
        
        setSigningIn(false);

        let formErrors = {};
        if(payload.email.length === 0) {
            formErrors["email"] = "Please enter an valid email address"
        } else if(payload.password.length <= 5) {
            formErrors["password"] = "Please enter an valid password"
        }

        setErrors(formErrors);

        if(isEmptyStruct(formErrors)) {
            setSigningIn(true);

            let response;

            if(showPage === "register") {
                response = await signUp(payload);
            } else {
                response = await signIn(payload);
            }

            if(response?.user) {
                onSignIn(response.user);
                setSigningIn(false);
            } else {
                formErrors = {
                    "general": "Invalid login. Please try again."
                }

                setErrors(formErrors);
                setSigningIn(false);
            }
        }
    }, [
        showPage,
        email,
        password
    ]);

    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100vh', 
        width: '100vw'
    }}>
        <Box sx={{
            position: 'fixed',
            top: 20,
            left: 20,
            display: 'flex',
            alignItems: 'center',
            fontSize: 18,
            fontWeight: 700
        }}>
            <Logo sx={{ mr: 3 }} />
            <Box sx={{ ml: 2 }}>
                { process.env.APP_NAME }
            </Box>
        </Box>

        <Card>
            <Box sx={{ width: 300, minHeight: 300, position: 'relative' }}>
                <Typography variant="h2">
                    { labels.title }
                </Typography>
                {
                    labels.desc &&
                    <Typography>
                        { labels.desc }
                    </Typography>
                }
                <Box sx={{ mt: 4 }}>
                    <form onSubmit={ onSubmit }>
                        <Box>
                            <TextField
                                value={ email }
                                label="Email address"
                                size="small"
                                type="email"
                                placeholder="name@example.com"
                                required
                                autoFocus
                                error={ !!errors['email'] }
                                helperText={ errors['email'] }
                                onChange={ event => {
                                    setEmail(event.target.value)
                                } }
                                inputProps={{
                                    minLength: 0,
                                    maxLength: 100
                                }}
                                disabled={ signingIn }
                                fullWidth />

                            {
                                (showPage === "login") &&
                                <TextField
                                    value={ password }
                                    label="Password"
                                    size="small"
                                    placeholder="Password"
                                    type={ showPassword ? "text" : "password" }
                                    required
                                    error={ !!errors['password'] }
                                    helperText={ errors['password'] }
                                    disabled={ signingIn }
                                    onChange={ event => {
                                        setPassword(event.target.value)
                                    } }
                                    inputProps={{
                                        minLength: 5,
                                        maxLength: 100
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                onClick={ () => { setShowPassword(!showPassword) } }
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth />
                            }

                            
                        </Box>

                        {
                            errors["general"] &&
                            <Typography sx={{ mb: 2, color: 'red' }}>
                                { errors['general'] }
                            </Typography>
                        }
                        
                        <Button 
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={ submitDisabled }>
                                {
                                    labels.submit
                                }
                        </Button>

                        

                        <Box sx={{ mt: 4 }}>
                            {
                                showPage === "login" &&
                                <Button 
                                    variant="text"
                                    fullWidth
                                    onClick={ () => {
                                        setPassword("");
                                        setShowPage("forgot")
                                    } }
                                    sx={{ mt: 1 }}>
                                        Forgot Password?
                                </Button>
                            }

                            {
                                showPage === "forgot" &&
                                <>
                                    <Button 
                                        variant="outlined"
                                        fullWidth
                                        onClick={ () => {
                                        } }
                                        sx={{ mt: 1 }}>
                                        Create a new account
                                    </Button>
                                    <Button 
                                        variant="outlined"
                                        fullWidth
                                        onClick={ () => {
                                        } }
                                        sx={{ mt: 1 }}>
                                        Sign In
                                    </Button>
                                </>
                            }
                        </Box>

                        

                        <Box sx={{ 'display': 'none' }}>
                            <Typography>
                                Don't have an account?
                            </Typography>
                            <Button 
                                variant="text"
                                fullWidth
                                onClick={ () => {
                                    setPassword("");
                                    setShowRegister(!showRegister)
                                } }
                                sx={{ mt: 1 }}>
                                    {
                                        showRegister ? "Already have an account? Sign in." : "Create new"
                                    }
                            </Button>

                        </Box>

                        
                  

                        
                    </form>
                </Box>

                {
                    loading &&
                    <Box sx={{ 
                        position: 'absolute', 
                        'top': 0, 
                        left: 0,
                        background: 'rgba(255,255,255,0.6)',
                        width: '100%',
                        height: '100%',
                        zIndex: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress />
                    </Box>
                }
            </Box>
            
        </Card>
      </Box>
    )
*/
  