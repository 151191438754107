import Supabase from "@api/providers/Supabase";
import BrowserStorage from "@api/providers/BrowserStorage";
import FileStorage from "@api/providers/FileStorage";

let Provider = Supabase;
if(process.env.API_PROVIDER === "local") {
    Provider = BrowserStorage;
} else if(process.env.API_PROVIDER === "file") {
    Provider = FileStorage;
}

export default new Provider();