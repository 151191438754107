import {
    useState,
    useEffect
} from "react";

import FormLabel from "honeyjar/FormLabel"
import Input from "./Input";

export default function TextInput({
    label,
    errors,
    prefix,
    suffix,
    msg,
    noMargin=false,
    labelClassName,
    ...props
}: {
    label?: any,
    errors?: object[],
    name?:string,
    prefix?: any,
    suffix?:any,
    value: any,
    className?: string,
    placeholder?: string,
    type?:string,
    onChange: any,
    noMargin?:boolean,
    labelClassName?:string,
    msg?: any,
    required?:boolean
}) {

    const [ errorMsg, setErrorMsg ] = useState("");
    useEffect(() => {
        if(errors?.length && props.name) {
            let error = errors.find(err => err.name === props.name);

            if(error) {
                setErrorMsg(error.message)
            } else {
                setErrorMsg("")
            }
        } else {
            setErrorMsg("")
        }
    }, [ errors, props.name ])

    return (
        <div className={ noMargin ? '' : 'mb-4' }>
            {
                label &&
                <div className="mb-2">
                    <FormLabel className={ labelClassName }>{label}</FormLabel>
                </div>
            }
            
            <div className="flex items-center">
                {
                    prefix &&
                    <div className="mr-2">
                        { prefix }
                    </div>
                }
                <Input
                    error={ !!errorMsg }
                    { ...props } />
                {
                    suffix &&
                    <div className="ml-2">
                        { suffix }
                    </div>
                }
            </div>
                
            {
                msg && 
                <div className="text-sm text-slate-600">
                    { msg }
                </div>
            }
            {
                errorMsg &&
                <div className="text-sm text-red-600">
                    { errorMsg }
                </div>
            }
        </div>
    )
}