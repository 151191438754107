import { v4 as uuidv4 } from 'uuid';
import APIProvider from "@api/providers/APIProvider";
import generateOrderNumber from 'utils/generateOrderNumber';
import LocalStorage from '@api/LocalStorage';

export default class BrowserStorage extends APIProvider {
    cache: any;

    constructor() {
        super();
        
        this.cache = new LocalStorage({
            key: "opentummy-admin"
        });
        this.events = {};
    }

    // ---  User  ---
    signIn({ email, password }) {
        const user = {
            "id": uuidv4(),
            "email": email,
            "password": password,
            "isAdmin": true
        }

        this.cache.set("user", user);

        return {
            user
        }
    }

    signOut() {
        this.cache.clear();
    }

    getUserFromSession() {
        return this.cache.get("user");
    }

    signUp({ email, password }) {
        return this.signIn({ email, password })
    }

    resetPassword() {
        return true
    }

    // ---- Site  ----
    createSite(site) {
        site.plan_id = 2;
        
        if(!site?.id) {
            site.id = uuidv4();
        }

        this.cache.set("site", site);

        return site.id;
    }

    async getSite(userId) {
        return this.cache.get("site");
    }

    async getSiteByDomain(domain, isFreeDomain) {
        return this.cache.get("site");
    }

    saveSite(payload) {
        let site = this.cache.get("site") || {};

        site = {
            ...site,
            ...payload
        }

        this.cache.set("site", site)
    }


    // ---- Locations ----
    createLocation(payload) {
        let locations = this.cache.get("locations") || [];

        if(!payload.id) {
            payload.id = uuidv4();
        }

        locations.push(payload);
        this.cache.set("locations", locations);
    }

    getLocation(id) {
        let locations = this.cache.get("locations") || [];
        
        return locations.find(location => location.id === id) || locations?.[0];
    }

    saveLocation(payload) {
        let locations = this.cache.get("locations") || [];

        for(let i = 0; i < locations.length; i++) {
            if(locations[i].id === payload.id) {
                locations[i] = {
                    ...locations[i],
                    ...payload
                }
            }
        }
        
        return this.cache.set("locations", locations);
    }

    // --- Collections ---
    getCollections(id) {
        return this.cache.get("collections") || [];
    }

    createCollection(payload) {
        let collections = this.cache.get("collections") || [];

        if(!payload.id) {
            payload.id = uuidv4();
        }

        collections.push(payload);

        this.cache.set("collections", collections);

        return payload.id;
    }

    async saveCollection(payload) {
        let collections = this.cache.get("collections").map(collection => {
            if(collection.id === payload.id) {
                return {
                    ...collection,
                    ...payload
                }
            } else {
                return collection
            }
        })

        this.cache.set("collections", collections);

        /*

        const siteData  = await this._retriveSite();

        for(let i = 0; i < siteData.collections.length; i++) {
            if(siteData.collections[i].id === payload.id) {
                siteData.collections[i] = {
                    ...siteData.collections[i],
                    ...payload
                };

            }
        }

        await this._writeSite(siteData);
        */
    }

    deleteCollection(collectionId) {
        let collections = this.getCollections("").filter(collection => {
            return collection.id !== collectionId
        })

        this.cache.set("collections", collections);
    }

    


    // ---- Products ----
    getProducts(userId) {
        return this.cache.get("products") || [];
    }

    getProduct(productId) {
        return this.getProducts("").find(product => product.id === productId)
    }

    createProduct(payload) {
        let products = this.getProducts(payload.user_id) || []

        if(!payload.id) {
            payload.id = uuidv4();
        }

        products.push(payload);

        this.cache.set("products", products);

        return payload.id;
    }

    saveProduct(payload) {
        let products = this.getProducts(payload.user_id).map(product => {
            if(product.id === payload.id) {
                return {
                    ...product,
                    ...payload
                }
            } else {
                return product;
            }
        })

        this.cache.set("products", products);
    }

    deleteProducts(product_ids) {
        let products = this.getProducts("").filter(product => {
            return !product_ids.includes(product.id)
        });

        this.cache.set("products", products);
    }

    removeCatalog(userId) {
        this.cache.unset("products");
        this.cache.unset("collections");
    }

    createProducts(newProducts) {
        let productIds = [];

        for(var i = 0; i < newProducts.length; i++) {
            if(!newProducts[i].id) {
                newProducts[i].id = uuidv4();
            }

            productIds.push(newProducts[i].id)
        }

        let products = this.cache.get("products") || [];

        products = [
            ...products,
            ...newProducts
        ]

        this.cache.set("products", products);

        return productIds;
    }

    // orders
    getOrders(userId, date) {
        if(window.__orders__) {
            return  window.__orders__;
        }

        let order = {
            "id": uuidv4(),
            "number": "AE271",
            "created_at": new Date().toISOString(), // may 30th 
            "schedued_at": new Date().toISOString(), // may 31st
            "incoming_at": new Date().toISOString(),
            "in_progress_at": new Date().toISOString(), // may 31st 1pm
            "due_at": new Date().setMinutes(new Date().getMinutes() + 20), // may 31st 115pm
            "ready_at": new Date().setMinutes(new Date().getMinutes() - 4), // may 31st 115pm
            "fulfilled_at": new Date().toISOString(),
            "cancelled_at": "",
            "lines": [
                {
                    "product": {
                        "id": "1",
                        "name": "Cheese Burger",
                        "price": 10
                    },
                    "options": [
                        {
                            id: "2",
                            key: "size",
                            label: "Size",
                            choices: [
                                {
                                    label: "Large",
                                    price: 4
                                }
                            ]
                        },
                        {
                            id: "2",
                            key: "toppings",
                            label: "Toppings",
                            choices: [
                                {
                                    label: "Tomatoes",
                                    price: 2
                                },
                                {
                                    label: "Extra Cheese",
                                    price: 1.5
                                }
                            ]
                        }
                    ],
                    "note": "no pickles.",
                    "total": 8
                },
                {
                    "product": {
                        "id": "1",
                        "name": "Fries",
                        "price": 20
                    },
                    "options": [
                        {
                            id: "2",
                            key: "size",
                            label: "Size",
                            choices: [
                                {
                                    label: "Large",
                                    price: 1
                                }
                            ]
                        },
                        {
                            id: "2",
                            key: "toppings",
                            label: "Condiments",
                            choices: [
                                {
                                    label: "Ketchup",
                                    price: 0
                                }
                            ]
                        }
                    ],
                    "note": "no pickles.",
                    "total": 8
                }
            ],
            "payment": {
                "method": "store"
            },
            "customer": {
                "name": "Amanda Cooper",
                "phone": "7788818183",
                "email": "amanda@test.com"
            },
            "total": 19.6,
            "taxes": 2.1,
            "subtotal": 17.5,
            "status": "incoming"
        };

        window.__orders__ = [
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber()
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "due_at": new Date().setMinutes(new Date().getMinutes() + 30),
                price: 12,
                customer: {
                    "name": "Bradley Walden",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "due_at": new Date().setMinutes(new Date().getMinutes() + 5),
                price: 8,
                status: "progress",
                customer: {
                    "name": "Taylor Poon",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "due_at": new Date().setMinutes(new Date().getMinutes() + 8),
                price: 24,
                status: "progress",
                customer: {
                    "name": "Stacy Kim",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                price: 15,
                status: "ready",
                customer: {
                    "name": "Steven Smith",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 17),
                subtotal: 15.23,
                status: "fulfilled",
                customer: {
                    "name": "Elana Spowage",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 45),
                subtotal: 11.43,
                status: "fulfilled",
                customer: {
                    "name": "Jim He",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 32),
                subtotal: 22.65,
                status: "fulfilled",
                customer: {
                    "name": "Kevin Lail",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 36),
                subtotal: 23.15,
                status: "fulfilled",
                customer: {
                    "name": "Jas Gilles",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 45),
                subtotal: 14.54,
                status: "fulfilled",
                customer: {
                    "name": "Albert Fung",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 65),
                subtotal: 45.21,
                status: "fulfilled",
                customer: {
                    "name": "Aziz Lawal",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "fulfilled_at": new Date().setMinutes(new Date().getMinutes() - 72),
                subtotal: 89.12,
                status: "fulfilled",
                customer: {
                    "name": "Benny Luk",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "cancelled_at": new Date().setMinutes(new Date().getMinutes() - 72),
                subtotal: 89.12,
                status: "cancelled",
                customer: {
                    "name": "Cancelled",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            },
            {
                ...order,
                id: uuidv4(),
                number: generateOrderNumber(),
                "cancelled_at": new Date().setMinutes(new Date().getMinutes() - 72),
                subtotal: 89.12,
                status: "pending",
                customer: {
                    "name": "Created Pending",
                    "phone": "7788818183",
                    "email": "amanda@test.com"
                }
            }
        ];

        return window.__orders__;
    }
    
    getOrder({
        siteId,
        orderId,
        locationId
    }) {
        let orders = this.getOrders(siteId, orderId);
        return {
            ...orders[0],
            site_id: siteId,
            location_id: locationId
        };
    }

    checkOrderNumberExists(number, date) {
        return false;
    }

    subscribeToOrders({
        callback
    }) {
        this.events.subscribeToOrders = callback;

        setTimeout(() => {
            this.events.subscribeToOrders({
                ...this.getOrder({}),
                id: uuidv4(),
                number: "SA123",
                customer: {
                    name: "Ryan King",
                    phone: "12121212121"
                },
                created_at: new Date().toISOString(),
                status: "pending"
            });
        }, 3000)

        /*
        setTimeout(() => {
            this.events.subscribeToOrders({
                ...this.getOrder({}),
                name: "Amos So",
                status: "pending"
            });
        }, 6000)
        */
    }

    saveOrder(order) {
    }

    createOrder(order) {
        order.id = uuidv4();
        order.created_at = new Date().toISOString()
        

        return order;
    }

    subscribeToOrder(orderId, callback) {
        
    }

    


















}