import {
    useState,
    useEffect
} from "react";

export default function Input({
    error=false,
    size="",
    ...props
}) {

    const [ className, setClassName ] = useState(`py-1.5 px-2.5 border w-full rounded`);

    useEffect(() => {
        let className = `${ error ? "border-red-600" : "border-slate-400" } border w-full rounded disabled:bg-slate-200 disabled:text-slate-500 disabled:cursor-not-allowed`;

        if(props.className) {
            className += ` ${ props.className }`
        }

        if(size === "large") {
            className += " py-3 px-4 text-lg"
        } else {
            className += " py-1.5 px-2.5"
        }

        setClassName(className);
    }, [
        error,
        props.className,
        size
    ])

    return (
        <input
            type="text"
            { ...props }
            className={ className }/>
    )
}

