import APIProvider from "@api/APIProvider";

export async function createProduct(userId, payload) {
  return await APIProvider.createProduct(userId, payload);
}

export async function getProducts(userId, productIds=[]) {
  return await APIProvider.getProducts(userId, productIds=[]);
}


export async function getProduct(productId) {
  return await APIProvider.getProduct(productId);
}

export async function saveProduct(payload) {
  return await APIProvider.saveProduct(payload);
}

export async function deleteProducts(productIds) {
  return await APIProvider.deleteProducts(productIds);
}


export async function getCollections(userId) {
  return await APIProvider.getCollections(userId);
}

export async function saveCollection(payload) {
  return await APIProvider.saveCollection(payload);
}

export async function createCollection(payload) {
  return await APIProvider.createCollection(payload);
}

export async function addProductToCollection(collectionId, productIds) {
  return await APIProvider.addProductToCollection(collectionId, productIds);
}

export async function deleteCollections(collectionIds) {
  return await APIProvider.deleteCollections(collectionIds);
}

// todo (transacation)
export async function createCatalog(site, catalog) {
  await APIProvider.removeCatalog(site.user_id);

  let collectionIds = [];

  for(var i = 0; i < catalog.length; i++) {
    let collection = catalog[i];
    
    if(collection?.products?.length) {

      let products = collection.products.map(product => {
        return {
          ...product,
          user_id: site.user_id
        }
      })

      let productIds = await APIProvider.createProducts(products);

      let collectionId = await APIProvider.createCollection({
        user_id: site.user_id,
        name: collection.name,
        product_ids: productIds
      })

      collectionIds.push(collectionId)
    }
  }
  
  await APIProvider.saveSite({
    id: site.id,
    properties: {
      ...(site.properties || {}),
      collection_ids: collectionIds
    }
  });

  return {
    collectionIds
  }
}

export async function getCatalog(userId) {
  const catalog = [];

  const collections = await getCollections(userId);
  var productIds = [];
  collections?.forEach(collection => {
    productIds = [
      ...productIds,
      ...collection.product_ids
    ]
  })

  if(productIds.length) {
    const products = await getProducts(userId, productIds);

    collections.forEach(collection => {
      let catalogProducts = products.filter(product => collection.product_ids.includes(product.id));

      catalog.push({
        name: collection.name,
        products: catalogProducts
      })
    })
  }
  
  return catalog;
}