
import type { AppProps } from 'next/app'
import { useState, useContext, useEffect } from "react";
import SiteContext from "context/SiteContext";
import UserContext from "context/UserContext";
import AppLoading from "components/core/AppLoading"
import Router from 'next/router'

import {
  getSite
} from "@api/APIService";

import useAuthSession from 'hooks/useAuthSession';

import LoginPage from "components/pages/LoginPage";

const AppContextProvider = props => {
  const user = useContext(UserContext);
  const [ loading, setLoading ] = useState(true);
  const [ site, setSite ] = useState({});

  useEffect(() => {
    async function fetchData() {
      let site;
      try {
        site = await getSite(user.id);
      } catch(e) {
        console.log("unable to fetch the site.")
      }

      if(site) {
        setSite(site);
        setLoading(false);
      } else {
        Router.push('/onboard');
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      }
    }

    if(user.id) {
      fetchData();
    }
  }, [])
  
  if(loading) {
    return <AppLoading />
  }

  return (
    <SiteContext.Provider value={ { site, setSite } }>
      { props.children }
    </SiteContext.Provider>
  )
}

export default function AdminApp(props: AppProps) {
  const {
      Component, 
      pageProps
  } = props;

  const { user, status, signIn } = useAuthSession();

  return (
    <div>
      {
        status === "loading" 
        ?
        <AppLoading />
        :
        <>
          {
            user
            ?
              <UserContext.Provider value={ user }>
                <AppContextProvider>
                  <Component {...pageProps} />
                </AppContextProvider>
              </UserContext.Provider>
            :
            <LoginPage
              onSignIn={ user => {
                signIn(user)
              } } />
          }
        </>
      }
    </div>
  )
}
