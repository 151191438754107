import APIProvider from "@api/APIProvider";

export async function createPage(payload) {
  return await APIProvider.createPage(payload);
}

export async function deletePage(pageId) {
  return await APIProvider.deletePage(pageId);
}

export async function getPage(userId, slug) {
  return await APIProvider.getPage(userId, slug);
}

export async function getPageBySlug(userId, slug) {
  return await APIProvider.getPageBySlug(userId, slug);
}


// TODO -- Refractor this please.
// get from the page template maybe....
export async function getPageTemplate(type) {
  let page = {
    "sections": []
  }

  if(type === "home") {
    page.sections = [
      {
        "type": "restaurantHero",
        "medias": [
            {
              "provider": "unsplash",
              "src": "1556280725-d0a1d82c811b",
              "w": 500,
              "h": 500
            }
        ]
      },
      {
        "type": "banner",
        "title": "About Us",
        "desc": "The starting point for any endeavor is an idea. It could be starting a business, transforming a hobby into something bigger, or sharing a creative project with the world. The way you present your story online is crucial. Don't worry about appearing professional, be yourself. <br/><br/> With over 1.5 billion websites, it's your story that will make yours stand out. If you read your words and don't hear your own voice, it's an indication that you need to do more work."
      }
    ]
  } else if(type === "gallery") {
    page.sections = [
      {
        "type": "gallery"
      }
    ]
  }

  return page;
}