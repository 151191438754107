import APIProvider from "@api/APIProvider";

export async function saveSection(payload) {
  return await APIProvider.saveSection(payload);
}

export async function createSections(sections) {
  return await APIProvider.createSections(sections);
}

export async function getSections(sectionIds) {
  return await APIProvider.getSections(sectionIds);
}

export async function saveSections(sectionIds) {
  return await APIProvider.saveSections(sectionIds);
}